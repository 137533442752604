import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Modal, Button, Container } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import _ from 'lodash'

// import { getUserInfo, getDokuWikiEULA, agreeDokuWikiEULA } from '../action/ClientActions';
import { getUserInfo } from '../action/ClientActions';
import { apiCallGet } from '../action/RouterActions';

import HeaderNav from './dump/HeaderNav'
import Footer from './dump/Footer'
import SideMenu from './dump/menu/SideMenu';
import Auth from './Auth';
import getDomain from './helpers/helperRedirect';

import './Layout.sass';
// import CustomButton from './CustomButton';

const Layout = (props) => {

	const { user, company, children, whitelabel, loader, partnerPortalRedirect, toggleCustomLoader, sideMenuOpen } = props;

	const [interval, setIntervals] = useState(null);
	const [userinfo, setUserinfo] = useState({});
	// const [sideMenuOpen, setSideMenuOpen] = useState(JSON.parse(localStorage.getItem('openState')) === null ? false : JSON.parse(localStorage.getItem('openState')));
	// const [modalEula, setModalEula] = useState(true);
	// const [dokuWikiEula, setDokuWikiEula] = useState('');
	// const [agreeEula, setAgreeEula] = useState(false);
	// const [env, setEnv] = useState(location.hostname);

	useLayoutEffect(() => {
		// console.log('!!!Layout: ', props)
		const { updateUser } = props
		getUserInfo()
			.then(async (res) => {
				const { user } = res.data.response
				if (['partner', 'custom_partner', 'partner_view'].includes(user.roles[0]) && location.pathname === '/' && !partnerPortalRedirect.portal_url) {
					let url = `${getDomain()}/#${localStorage.getItem("s_t")}`;
					window.location.assign(url);
					return
				}
				// FWSD-5431, display the Firmware Portal menu option only if the company exists in the Firmware Rollout blacklist.
				// user.has_company_firmware_blacklist is marker if company of FleetManaged exists in firmware blacklist				
				if (user.roles[0] === 'fleet_manager') {
					const firmwareBlacklist = await apiCallGet('/companies/firmware/blacklist')
						.then((res) => {
							return res
						})
						.catch((error) => {
							let errDescription = 'Can not get the firmware blacklist'
							if (error.response?.data?.response?.error) {
								errDescription = error.response.data.response.error
							}
							toastr.error(errDescription)
						})
					user.has_company_firmware_blacklist = !!firmwareBlacklist.data.response.filter(({company_id}) => company_id === user.company_id).length
				}

				updateUser(user);
				getLastRelease(user);
				// getCompanyDevicesProblems(user);
			})
			.catch((error) => {
				console.log('!getUserInfo componentWillMount error: ', error.response, error);

				new Auth().deauthenticateUser();
				location.href = '/';
			});
		// Receives list of the partner whitelabel settings - FWSD-4278
		getPartnerWhitelabelSetting()
	}, []);

	useEffect(() => {
		// console.log('!!!Layout: ', props)
		// FWSD-4276 - set global style variables
		// console.log('SIDEMENU ', getComputedStyle(document.documentElement).getPropertyValue('--sidemenu-background-color'))
		// document.documentElement.style.setProperty('--header-logo-extended', 'url("/images/logo-side-menu.png")')
		// document.documentElement.style.setProperty('--header-logo', 'url("/images/player.png")')
		// // document.documentElement.style.setProperty('--loader-url', 'url("https://i.gifer.com/fetch/w300-preview/b4/b4d657e7ef262b88eb5f7ac021edda87.gif")')
		// document.documentElement.style.setProperty('--loader-url', 'url("/images/loader_form_send.gif")')

		// isEulaAgreement();
		if (isMobileOnly) {
			document.body.classList.add('mobile');
		}
	}, []);

	useEffect(() => {
		if (company && user && company.company_id) {
			getCompanyDevicesProblems(user)
		}

		return () => {
			if (interval) {
				clearInterval(interval)
				setIntervals(null)
			}
		}
	}, [company.company_id]);

	// Receives list of the partner whitelabel settings - FWSD-4278
	const getPartnerWhitelabelSetting = () => {
		const { updatePartnerWhitelabel } = props;
		apiCallGet('/partner/settings')
			.then((res) => {
				// console.log('getPartnerWhitelabelSetting res: ', res)
				const { settings } = res.data.response
				if (!_.isEmpty(settings)) {
					updatePartnerWhitelabel(JSON.parse(settings.settings))
				}
			})
			.catch((error) => {
				console.log('!getPartnerWhitelabelSetting error: ', error.response, error)
			})
	}

	const getLastRelease = () => {
		const { updateProjectVersion } = props;

		apiCallGet('/version.json')
			.then((res, err) => {
				const version = res.data.response
				updateProjectVersion(version)
			})
			.catch((error) => {
				console.log('!getProjectVersion error: ', error)
			})
	}

	const getCompanyDevicesProblems = (argUser) => {
		const { updateIsDevicesHaveProblems, company } = props;
		const userRole = argUser.roles[0];

		if (userRole === 'partner' && !company.name) {
			return
		}

		// const companyName = company.name || argUser.company_name
		// const companyPartner = company.partner || argUser.partner_company_name
		apiCallGet('/companies/{company_id}/devices/problems', { company_id: company.company_id })
			.then((res) => {
				// const { devices } = res.data.response
				// updateAssetsList(devices)
				const devicesHaveProblems = _.has(res.data.response, 'is_red_bubble') && res.data.response.is_red_bubble
				updateIsDevicesHaveProblems(devicesHaveProblems)
			})
			.catch((err) => {
				console.log('getCompanyDevices error: ', err)
			})

		if (interval) {
			clearInterval(interval)
			setIntervals(null)
		}
		setIntervals(setInterval(() => {
			apiCallGet('/companies/{company_id}/devices/problems', { company_id: company.company_id })
				.then((res) => {
					// const { devices } = res.data.response
					// updateAssetsList(devices)
					const devicesHaveProblems = _.has(res.data.response, 'is_red_bubble') && res.data.response.is_red_bubble
					updateIsDevicesHaveProblems(devicesHaveProblems)
				})
				.catch((err) => {
					console.log('getCompanyDevices error: ', err)
				})
		}, 1800000)) // 30mins
	}

	// const toggleSidenav = () => {
	// 	localStorage.setItem('openState', JSON.stringify(!sideMenuOpen))
	// 	setSideMenuOpen(!sideMenuOpen);
	// };

	// const isEulaAgreement = () => {
	// 	setInterval(() => {
	// 		getUserInfo()
	// 			.then((res, err) => {
	// 				const result = _.get(res, 'data.response.user.eula_was_viewed', true);
	// 				if (!result) {
	// 					getEULA(result);
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				console.log('!isEulaAgreement error', error);
	// 			});
	// 	}, 60000);
	// };

	// const getEULA = (result) => {
	// 	getDokuWikiEULA(env)
	// 		.then((res, err) => {
	// 			setDokuWikiEula(res.data);
	// 			setModalEula(result);
	// 		})
	// 		.catch((error) => {
	// 			console.log('getEULA error:', error);
	// 		});
	// };

	// const onAgreeEula = () => {
	// 	agreeDokuWikiEULA()
	// 		.then((res, err) => {
	// 			setModalEula(true);
	// 			setAgreeEula(false);
	// 		})
	// 		.catch((error) => {
	// 			console.log('agreeDokuWikiEula error', error);
	// 		});
	// };

	// const disagreeEula = () => {
	// 	new Auth().deauthenticateUser();
	// 	location.href = '/';
	// };

	// const handleScroll = () => {
	// 	const modalBodyCustom = document.getElementsByClassName('modal-body-custom');
	// 	modalBodyCustom[0].addEventListener('scroll', scrollEvent);
	// 	// console.log('modalContentHeight handleScroll', modalBodyCustom[0]);
	// };

	// const scrollEvent = (event) => {
	// 	const { offsetHeight, scrollHeight, scrollTop } = event.target;
	// 	const result = scrollHeight - offsetHeight;

	// 	const percent = Math.round((scrollTop / result) * 100);

	// 	if (percent > 85) {
	// 		setAgreeEula(true);
	// 	}
	// };

	// const exitModal = () => {
	// 	document.removeEventListener('scroll', scrollEvent);
	// };

	// FWSD-4276
	const isCustomLoaderExists = _.get(whitelabel, 'loader');

	isCustomLoaderExists && toggleCustomLoader(true);

	const isUserExist = user.roles;
	if (!isUserExist) {
		return (
			<div id="" className="container-fluid" />
		)
	}

	const isHomePage = location.pathname === '/';

	return (
		<Container fluid className="base-wrapper-container p-0 m-0">
			<SideMenu
				// open={sideMenuOpen}
				// toggleSidenav={toggleSidenav}
			/>
			<HeaderNav userinfo={userinfo} />
			<div className={`right-content-big-block ${sideMenuOpen ? '' : 'wider'}`}>

				{children}

				{!isHomePage && <Footer />}

				{loader}
			</div>

			{/* <Modal
				size="lg"
				show={!modalEula}
				onExit={exitModal}
				onEntered={handleScroll}
				dialogClassName="has-error"
				keyboard={false}
				backdrop="static"
				className="modal-eula"
				// className="notifications-modal modal-lg-size"
				// className='manage-vehicles-modal modal-lg-size'
				
			>
				<Modal.Header>
					<h2 className="text-center mb-20">End User License Agreement</h2>
					<div>Please read the following EULA in its entirety carefully. After having read the EULA and scrolling to the bottom, you can then click <b>Accept</b> to continue or <b>Decline</b> to be returned to the login page.</div>
				</Modal.Header>
				<Modal.Body bsPrefix="modal-body modal-body-custom release-modal">
					<div dangerouslySetInnerHTML={{ __html: dokuWikiEula }} />
				</Modal.Body>
				<Modal.Footer bsPrefix="modal-footer text-center">
					<div>By clicking <b>Accept</b> you are agreeing to the terms and conditions outlined in the EULA.</div>
					<div>
						<CustomButton
							disabled={!agreeEula}
							onClick={onAgreeEula}
							variant="primary"
							size="sm"
							title="You must read to the bottom of the EULA before agreeing"
							prefix={`${!agreeEula ? 'custom-disabled' : ''}`}
						>
							Accept
						</CustomButton>
					</div>
					<div>
						<CustomButton
							variant="link"
							prefix="red mr-0"
							onClick={disagreeEula}
						>
							Decline
						</CustomButton>
					</div>			
				</Modal.Footer>
			</Modal> */}
		</Container>
	);
}

Layout.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	whitelabel: PropTypes.objectOf(PropTypes.any).isRequired,
	children: PropTypes.objectOf(PropTypes.any).isRequired,
	updateUser: PropTypes.func.isRequired,
	updateProjectVersion: PropTypes.func.isRequired,
	updateIsDevicesHaveProblems: PropTypes.func.isRequired,
	updatePartnerWhitelabel: PropTypes.func.isRequired
}

const mapStateToProps = ({ user, company, loader, partnerPortalRedirect, dashboardData }) => ({
	user: user.user,
	whitelabel: user.whitelabel,
	company: company.company,
	partnerPortalRedirect: partnerPortalRedirect,
	loader: loader.loader,
	sideMenuOpen: dashboardData.sideMenuOpen,
})

const mapDispatchToProps = dispatch => ({
	updateUser: (user) => {
		dispatch({ type: 'GET_USER_SUCCESS', payload: user });
	},
	updateProjectVersion: (version) => {
		dispatch({ type: 'SET_PROJECT_VERSION', payload: version });
	},
	updateIsDevicesHaveProblems: (state) => {
		dispatch({ type: 'SET_IS_DEVICES_HAVE_PROBLEMS', payload: state });
	},
	updatePartnerWhitelabel: (state) => {
		dispatch({ type: 'SET_WHITELABEL_SETTINGS', payload: state });
	},
	toggleCustomLoader: (show) => {
		dispatch({ type: 'TOGGLE_CUSTOMLOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
