// FWSD-5402
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import _ from 'lodash'

import CustomButton from '../../CustomButton'
import { CustomButtonToggle, CustomMenu } from '../../DropdownComponents'
import LabelTag from '../../dump/LabelTag'

import momenttz from '../../helpers/momenttz'
import { isCustomUserPermissions } from '../../helpers/helperUsers'
import { profileSettings } from '../../helpers/helperProfile'
import { isInternalUser } from '../../helpers/constants'

import './fleetConfiguration.sass'

const ProfilePreviewPanel = (props) => {
	const { selected_profile, configuration, configurationdv6, user, webfleetDeviceId, showActions, saveProfileComment, onEditProfile, onClickSeeHistory, openMakePreferredProfileModal, openDeleteModal,  openFleetConfigurationModal, setConfiguration} = props
	
	const [isEditComments, setIsEditComments] = useState(false)
	const [settingsType, setSettingsType] = useState('dv6')
	const [state, setState] = useState(null)

	useEffect(() => {
		if (selected_profile) {
			setState({...selected_profile})
		}

		return () => {
			setIsEditComments(false)
			setSettingsType('dv6')
		}
	}, [selected_profile])

	if (!state || !selected_profile) {
		return (
			<div className="profile-preview-panel main-content-block no-selected-profile">
				Select a profile
			</div>
		)
	}

	const { name, devices_using, created_at, last_changed_ts, preferred, comments, event_types } = state
	const userRole = user.roles[0]
	const userPermissions = user.permissions
	const isDV6 = settingsType === 'dv6'
	const isDV4 = settingsType === 'dv4'
	const currentConfig = isDV6 ? configurationdv6 : configuration
	const isPreferredProfile = selected_profile && selected_profile.preferred
	const isDefaultProfile = selected_profile && selected_profile.name === 'Default'
	const isWebfleetMode = webfleetDeviceId.length > 0
	const isProfileEditAccess = (!['custom_user', 'custom_partner'].includes(userRole) 
		|| isCustomUserPermissions(userRole, userPermissions, 'settings_profile_edit')
		|| isCustomUserPermissions(userRole, userPermissions, 'settings_profile_delete')
	) 

	const getSettingValue = (settingArg) => {
		const { id, name, setting, type } = settingArg

		const getEventUploadType = (uploadType) => {
			switch (uploadType) {
				case 'alert_only':
					return 'Alert Only'
				case 'snapshot_only':
					return 'Snapshot Only'
				case 'video_snapshot':
					return 'Video/Snapshot'	
				default:
					return <span className="error-label">Not Available</span>
			}
		}

		const ADASMinimumSpeed = () => {
			let res = currentConfig.Event.Adas.Headway.SpeedThreshold
			if (currentConfig.Event.Adas.Headway.SpeedUnit === 'mph') {
				res = Math.round((res / 1.609344) * 1e2) / 1e2
			}
	
			return +res.toFixed(0)
		}

		const DMSSensitivity = (settingName) => {
			switch (currentConfig.Event.Dms[settingName].Sensitivity) {
				case -1:
					return 'Less Sensitive'
				case 0:
				case '':
					return 'Default'
				case 1:
					return 'More Sensitive'
				default:
					return <span className="error-label">Not Available</span>
			}
		}

		const channelQuality = (channelIndex) => {
			// console.log('currentConfig: ', channelIndex, currentConfig)
			// console.log('currentConfig props: ', props)

			if (!_.has(currentConfig, `Channels.${channelIndex}`)) {
				return <span className="error-label">Not Available</span>
			}	
			
			const { MainQuality, MainFPS} = currentConfig.Channels[channelIndex]
			if (isDV6) {
				if (((channelIndex === 0 && MainQuality === 0) || (channelIndex !== 0 && MainQuality === 1)) && MainFPS === 24) {
					return 'Most Storage'
				}
				if (MainQuality === 3 && MainFPS === 30) {
					return 'Best Video'
				}
				return 'Default'
			}
			else {
				if (MainQuality === 1 && MainFPS === 7) {
					return 'Most Storage'
				}
				if (MainQuality === 3 && MainFPS === 30) {
					return 'Best Video'
				}
				return 'Default'
			}
		}

		// if profileSettings setting key is filled then we can take his value from device configuration
		if (setting) {
			if (!_.has(currentConfig, setting)) {
				return <span className="error-label">Not Available</span>
			}	

			const settingValue = _.get(currentConfig, setting)	

			if (typeof settingValue == "boolean" || type === 'boolean') {
				if (settingValue === true || settingValue === 1) {
					return 'ON'
				}
				return 'OFF'
			}

			return settingValue
		}
		else {
			switch (id) {
				case  7: // Parking Mode - DV6
				case  59: // Parking Mode - DV4
					return currentConfig.General.IgnitionShutdownDelay === 0 ? 'OFF' : 'ON'
				case 9: // Potential Ch2 Obstruction Detection Voice Prompt
					switch (currentConfig.System.Chimes.CameraObstruction.Enabled) {
						case 0:
							return 'No Prompt'
						case 1:
							return 'One Prompt'
						case 2:
							return 'Continuous Prompt'
						default:
							return <span className="error-label">Not Available</span>
					}
				case 10: // Speed Unit - DV6
				case 60: // Speed Unit - DV4
					return currentConfig.System.SpeedUnit === 2 ? 'KPH' : 'MPH'
				
				case 26: // ADAS - Minimum Speed
					return ADASMinimumSpeed()
				
				case 93: // DMS - Distraction Sensitivity
					return DMSSensitivity('Distraction')
				case 32: // DMS - Yawning Sensitivity
					return DMSSensitivity('Yawning')
				case 35: // DMS - Dozing Sensitivity
					return DMSSensitivity('Dozing')
				case 38: // DMS - Phone Use Sensitivity
					return DMSSensitivity('PhoneUse')
				case 96: // DMS - Smoking Sensitivity
					return DMSSensitivity('Smoking')
				case 100: // DMS - Seatbelt Sensitivity
					return DMSSensitivity('Seatbelt')

				case 52: // Max Speed Threshold
				case 92: // Max Speed Threshold
					return currentConfig.System.SpeedUnit === 2 ? currentConfig.Event.HighSpeed.Threshold : (currentConfig.Event.HighSpeed.Threshold / 1.609344).toFixed(0)

				case 13: // Channel 1 Recording
				case 63: // Channel 1 Recording
					return channelQuality(0)
				case 16: // Channel 2 Recording
				case 66: // Channel 2 Recording
					return channelQuality(1)
				case 19: // Channel 3 Recording
				case 69: // Channel 3 Recording
					return channelQuality(2)
				case 72: // Channel 3 Recording
					return channelQuality(3)

				case  21: // ADAS - Forward Collision Upload Type - DV6
					return getEventUploadType(event_types.dv6_forward_collision)
				case 23: // ADAS - Headway Monitoring Upload Type
					return getEventUploadType(event_types.dv6_headway_monitoring)
				case 25: // ADAS - Tailgating Upload Type
					return getEventUploadType(event_types.dv6_following_distance)
				case 29: // DMS - Distraction Upload Type
					return getEventUploadType(event_types.dv6_distraction)
				case 31: // DMS - Yawning Upload Type
					return getEventUploadType(event_types.dv6_yawning)
				case 34: // DMS - Dozing Upload Type
					return getEventUploadType(event_types.dv6_drowsiness)
				case 37: // DMS - Phone Use Upload Type
					return getEventUploadType(event_types.dv6_phone_use)
				case 40: // Driver Event Upload Type
					return getEventUploadType(event_types.dv6_driver)
				case 42: // Harsh Acceleration Upload Type
					return getEventUploadType(event_types.dv6_harsh_accel)
				case 43: // Harsh Acceleration Sensitivity
					return Math.abs(currentConfig.Event.HarshAccel.Threshold * 0.28 / 9.81).toFixed(2)
				case 45: // Harsh Braking Upload Type
					return getEventUploadType(event_types.dv6_harsh_braking)
				case 46: // Harsh Braking Sensitivity
					return Math.abs(currentConfig.Event.HarshBraking.Threshold * 0.28 / 9.81).toFixed(2)
				case 48: // High G-Force Upload Type
					return getEventUploadType(event_types.dv6_fatal_gsensor)
				case 51: // Max Speed Upload Type
					return getEventUploadType(event_types.dv6_high_speed)
				case 95: // DMS - Smoking Upload Type
					return getEventUploadType(event_types.dv6_smoking)
				case 99: // DMS - Seatbelt Upload Type
					return getEventUploadType(event_types.dv6_seatbelt)

				case 78: // DMS - Distraction Upload Type - DV4
					return getEventUploadType(event_types.dv4_distraction)
				case 80: // DMS - Drowsiness/Looking Down Upload Type
					return getEventUploadType(event_types.dv4_drowsiness)
				case 82: // DMS - Phone Use/Hand to Head Upload Type
					return getEventUploadType(event_types.dv4_phone_use)
				case 84: // Driver Event Upload Type
					return getEventUploadType(event_types.dv4_driver)
				case 86: // External Driver Event Upload Type
					return getEventUploadType(event_types.dv4_ext_driver)
				case 88: // High G-Force Upload Type
					return getEventUploadType(event_types.dv4_fatal_gsensor)
				case 91: // Max Speed Upload Type
					return getEventUploadType(event_types.dv4_high_speed)
			}
		}

		return <span className="error-label">Not Available</span>		
	}

	const getSettingPrefix = (settingArg) => {
		const { id, name, setting } = settingArg

		switch (id) {
			case 6: // Parking Duration - DV6
			case 58: // Parking Mode - DV4
				return ' min'
			case 4: // Event Chime Volume
				return '%'
			case 26: // ADAS - Minimum Speed
				return ' '+currentConfig.Event.Adas.Headway.SpeedUnit.toUpperCase()
			case 27: // ADAS - Following Distance Time
				return ' seconds'
			case 43: // Harsh Acceleration Sensitivity
			case 46: // Harsh Braking Sensitivity
			case 49: // High G-Force Sensitivity
			case 89: // High G-Force Sensitivity
				return ' Gs'
			case 52: // Max Speed Threshold
			case 92: // Max Speed Threshold
				return currentConfig.System.SpeedUnit === 2 ? ' KPH' : ' MPH'
		}
	}

	// const getSettingBeginning = (settingArg) => {
	// 	const { id, name, setting } = settingArg

	// 	switch (id) {
	// 		case 43: // Harsh Acceleration Sensitivity
	// 			return '+'
	// 		case 46: // Harsh Braking Sensitivity
	// 			return '-'
	// 	}
	// }

	const optsComments = {}
	if (!isProfileEditAccess || !isEditComments) {
		optsComments.readOnly = 'readOnly'
	}

	// console.log('PROPS ProfilePreviewPanel: ', props)
	// console.log('STATE ProfilePreviewPanel: ', state)
	return (
		<div className="profile-preview-panel main-content-block">
			<div className="panel-header mb-20">
				<div className="name-wrapper">
					<div className="profile-name">{name}</div>
					{preferred &&
						<CustomButton
							size="icon"
							variant="primary"
							prefix="no-cursor mr-20"
						>
							Preferred
						</CustomButton>
					}
				</div>

				{/* Actions dropdown menu */}
				{showActions && (
					<div className='subheader-section'>
						<Dropdown
							className='actions-dropdown'
							role="menuitem"
						>
							<Dropdown.Toggle as={CustomButtonToggle} variant="primary" prefix="actions dropdown-toggle btn btn-default">
								Actions
								<span className="caret" />
							</Dropdown.Toggle>
							<Dropdown.Menu as={CustomMenu}>
								{/* Edit */}
								{!isDefaultProfile && isProfileEditAccess && <Dropdown.Item as="button" onClick={onEditProfile}>Edit</Dropdown.Item>}
								{/* Make Preferred Profile */}
								{!isPreferredProfile && isProfileEditAccess &&
									<Dropdown.Item as="button" onClick={openMakePreferredProfileModal}>Make Preferred Profile</Dropdown.Item>
								}
								{/* Push to Vehicles */}
								{!isWebfleetMode && isCustomUserPermissions(userRole, userPermissions, 'settings_profile_view') 
									&& (
										<Dropdown.Item
											as="button"
											onClick={() => {
												openFleetConfigurationModal();
												setConfiguration({ isPushing: true })
											}}
										>Push to Vehicles</Dropdown.Item>
								)}		
								{/* See Change History						 */}
								{!isDefaultProfile && ['system_admin', 'customer_service', 'partner'].includes(userRole)
									&& <Dropdown.Item as="button" onClick={onClickSeeHistory}>See Change History</Dropdown.Item>
								}
								{/* Delete */}
								{!isDefaultProfile && isCustomUserPermissions(userRole, userPermissions, 'settings_profile_delete') &&
									<>
										<Dropdown.Divider />
										<Dropdown.Item as="button" bsPrefix="dropdown-item delete" onClick={openDeleteModal}>Delete</Dropdown.Item>
									</>
								}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				)}
			</div>

			<main className="edit-asset-block">
				{/** INFO */}
				<section className="info-section mb-20">
					<h3 className="category-title">
						<span className="mr-30">Info</span>
					</h3>
					<div className="info-category">
						<div className="device-param">
							<strong>Profile is applied to:</strong> {devices_using} cameras
						</div>
						<div className="device-param">
							<strong>Created on:</strong> {momenttz(created_at).format('MM/DD/YYYY h:mm:ss A') || 'Not Available'}
						</div>
						<div className="device-param">
							<strong>Last updated:</strong> {momenttz(last_changed_ts).format('MM/DD/YYYY h:mm:ss A') || 'Not Available'}
						</div>
					</div>
				</section>

				{/** COMMENTS */}
				<section className="info-section mb-20 comments">
					<h3 className="category-title">
						<span className="mr-30">Comments</span>
						{isProfileEditAccess
							? isEditComments
								? <span>
									<CustomButton
										variant="link"
										prefix="red"
										onClick={() => {
											// setGlobalThreshold(defaultGlobalThreshold);
											setState((prevState) => ({ ...prevState, comments: selected_profile.comments }))
											setIsEditComments(false)
										}}
									>
										Cancel
									</CustomButton>
									<CustomButton
										variant="link"
										onClick={() => {
											saveProfileComment(comments)
											setIsEditComments(false)
										}}
									>
										Save
									</CustomButton>
								</span>
								: <CustomButton variant="link" onClick={() => setIsEditComments(true)}>Edit</CustomButton>
							: ''
						}
					</h3>
					<div className="info-category">
						<textarea
                            className={`${(isProfileEditAccess && isEditComments) && 'editable'}`}
                            placeholder='No Comments...'
                            value={comments || ''}
                            onChange={(e) => setState((prevState) => ({ ...prevState, comments: e.target.value }))}
							{...optsComments}
                        />
					</div>
				</section>

				{/** PROFILE SETTINGS */}
				<section className="info-section mb-20">
					<h3 className="category-title">
						<span className="mr-30">Profile Settings</span>
					</h3>
					<div className="toggle-wrapper button-group mb-20">
						<div className="toggle-buttons">
							<CustomButton
								variant='toggle'
								active={isDV6}
								value="dv6"
								onClick={() => setSettingsType('dv6')}
							>
								DV6
							</CustomButton>
							<CustomButton
								variant='toggle'
								active={isDV4}
								value="dv4"
								onClick={() => setSettingsType('dv4')}
							>
								DV4
							</CustomButton>
						</div>
					</div>
					{
						Object.keys(profileSettings[settingsType]).map((category) => {
							return (
								<div key={category} className="info-category">
									<h4 className="info-title">{category.toUpperCase()}</h4>
									{
										profileSettings[settingsType][category].map((setting) => {
											if (setting.isInternal && !isInternalUser(userRole)) {
												return <div key={setting.id} />
											}
											else {
												return (
													<div key={setting.id} className="device-param">
														{/* <strong>{setting.name}:</strong> {getSettingBeginning(setting)}{getSettingValue(setting)}{getSettingPrefix(setting)} */}
														<strong>{setting.name}{setting.depreciated ? <span className="depreciated">DEPRECIATED</span>: ''}:</strong> {getSettingValue(setting)}{getSettingPrefix(setting)}
														{setting.isInternal && <LabelTag text='Internal' tag_type='ml-10 internal' />}
													</div>
												)
											}
										})
									}
								</div>
							)
						})
					}
				</section>
			</main>
		</div>
	)
}

ProfilePreviewPanel.propTypes = {
	selected_profile: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]),
	configuration: PropTypes.objectOf(PropTypes.any),
	configurationdv6: PropTypes.objectOf(PropTypes.any),
	saveProfileComment: PropTypes.func,
	onEditProfile: PropTypes.func,
	onClickSeeHistory: PropTypes.func,
	openMakePreferredProfileModal: PropTypes.func,
	openDeleteModal: PropTypes.func,
	openFleetConfigurationModal: PropTypes.func,
	setConfiguration: PropTypes.func,
	// withArrow: PropTypes.bool,
	// onClickProfileName: PropTypes.func.isRequired,
	// onClickProfileComment: PropTypes.func.isRequired
}

// ProfilePreviewPanel.defaultProps = {
// 	withArrow: false,
// };

export default ProfilePreviewPanel
