import React, { useState, useEffect, useRef } from 'react'
import { Button, Tabs, Tab } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DateTime from 'react-datetime';
import classnames from 'classnames';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
// import { useHistory } from "react-router-dom"

import Recent24 from '../eventAlertSearch/Recent24'
import FleetSearch from '../eventAlertSearch/FleetSearch'
import constants from '../../helpers/helperEventAlertsSearch'
import { paginationExportOptions, tableExportOptions } from '../../helpers/table'
import momenttz from '../../helpers/momenttz'
import { isInternalUser } from '../../helpers/constants'
// import { eventTypeDetection } from '../../helpers/helperEvents'
import EventsAlertsTab from '../eventAlertSearch/EventsAlertsTab';
import { apiCallGet } from '../../../action/RouterActions'
import {
	getAlertsOfDevice, getEventsOfCompany, requestCustomAlertVideo, requestCustomAlertSnapshot, getEventById
} from '../../../action/AlertActions';
import { sendGA } from '../../helpers/helperUsers'

import 'react-datetime/css/react-datetime.css';
// import 'react-select/dist/react-select.css';
import '../../dump/menuComponents/treeview.sass';
import CustomButton from '../../CustomButton';

const LIMIT_IDS = 250;

const getAlarmTitle = (collection, alertType) => {
	if (alertType) {
		if (alertType.indexOf('||') > -1) {
			const type = _.reduce(alertType.split('||'), (memo, num) => {
				const obj = _.find(collection, o => o.id === num.toLowerCase());
				if (obj) {
					num = obj.title
				}

				return `${memo + num}, `;
			}, '')

			return type.slice(0, -2);
		}

		let obj = _.find(collection, o => o.id === alertType.toLowerCase());
		if (obj) {
			return obj.title
		}
		else {
			obj = _.find(constants.dmscheckboxes, o => o.id === alertType.toLowerCase())
			return obj ? obj.title : alertType
		}
	}

	return '';
}

const TrackTraceAlertSearch = (props) => {
	const { devices, type, alertsearch, updateAlertsearch, company_id, company_name, partner_name, user_role, user_permissions, webfleetDeviceId, loaderModal, toggleLoaderModal, setEventsRedirect } = props;

	// const history = useHistory();
	const tableEventSearch = useRef(null);

	const [state, setState] = useState({
		drivers: [],
		alerts: [],
		initialData: [],
		searchText: '',
		errMessage: '',
		tabKey: 4,
		showSearchBlock: true,
		start_date: moment().subtract(1, 'days'),
		end_date: moment(),
		selectedDeviceID: null,
		selectedAlertTypes: [],
		selectedEventTypes: [],
		selectedDevices: [],
		selectedAlertType: [],
		selectedEventType: [],
		windowHeight: 560,
		windowWidth: 900,
		loaderModal: false,
		page: 1,
		sizePerPage: 10,
		sortName: 'timestamp',
		sortOrder: 'desc',
	});


	useEffect(() => {
		// debugger
		// if (['tt_local', 'storage_link', 'storage_local'].indexOf(type) > -1) { // FWSD-6377
		if (['tt_local', 'storage_local'].indexOf(type) > -1) {
			getFleetDrivers();
			getRecent24Events();
		} else {
			// setState({ ...state, ...alertsearch.alertsearch });
			setState((prevState) => { return { ...prevState, ...alertsearch.alertsearch } })

			if (type === 'tt_link') {
				updateAlertsearch(null);
			}
			if (type === 'storage_link') {
				getFleetDrivers();
				if ((state.tabKey === 4) && !alertsearch.alertsearch.tabKey) getRecent24Events();
			}
		}

		// Only for Tenna and internal users (FWSD-7355)
		if (props.user_company_id === 2171 || isInternalUser(user_role)) {
			constants.eventcheckboxes.push({ id: 'camerablock', title: 'Potential Ch2 Obstruction' })
		}
	}, []);

	useEffect(() => {
		// debugger
		// console.log('!!!!!!!!! componentWillReceiveProps TrackTraceAlertSearch', nextProps, props)

		if (state.initialData.length && company_name && partner_name) {
			// we can update just Recent24Events and "Fleet Alerts & Events" data because other search types require selected vehicle
			getFleetDrivers(company_id);
			if (state.tabKey === 4) {
				getRecent24Events(partner_name, company_name)
			} else if (state.tabKey === 3) {
				searchAlertsEvents(partner_name, company_name)
			}
		}
	}, [company_name, partner_name]);

	const hideWindow = () => {
		const { onHide } = props;
		// setState({ ...state, tabKey: 4, alerts: [], initialData: [] });
		setState((prevState) => { return { ...prevState, tabKey: 4, alerts: [], initialData: [] } })
		onHide();
	}

	const getSelectedDevices = (data) => {
		const { selectedDevices, selectedAlertType, selectedEventType } = data;
		// setState({ ...state, selectedDevices, selectedAlertType, selectedEventType });
		setState((prevState) => { return { ...prevState, selectedDevices, selectedAlertType, selectedEventType } })
	}

	const getSelectedData = (data) => {
		const { selectedDeviceID, selectedAlertTypes, selectedEventTypes } = data;
		// setState({ ...state, selectedDeviceID, selectedAlertTypes, selectedEventTypes });
		setState((prevState) => { return { ...prevState, selectedDeviceID, selectedAlertTypes, selectedEventTypes } })
	}

	const getSelectedAlerts = (types) => {
		// setState({ ...state, selectedAlertTypes: types });
		setState((prevState) => { return { ...prevState, selectedAlertTypes: types } })
	}

	const getSelectedEvents = (types) => {
		// setState({ ...state, selectedEventTypes: types });
		setState((prevState) => { return { ...prevState, selectedEventTypes: types } })
	}

	const updateAlertsList = (config) => {
		// setState({ ...state, ...config });
		setState((prevState) => { return { ...prevState, ...config } })
	}

	const getFleetDrivers = (companyId = null) => {
		const paramCompanyId = companyId || company_id;
		apiCallGet('/company/{paramCompanyId}/driver', { paramCompanyId })
			.then((res, err) => {
				console.log('!getFleetDrivers res:', res, err)

				const { drivers } = res.data.response;
				// setState({ ...state, drivers })
				setState((prevState) => { return { ...prevState, drivers } })
			})
			.catch((error) => {
				console.log('!getFleetDrivers error:', error.response, error)
				// setState({ ...state, drivers: [] })
				setState((prevState) => { return { ...prevState, drivers: [] } })

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const getRecent24Events = (partnerName = null, companyName = null) => {
		const data = {
			user_role,
			company_name: companyName || company_name,
			partner_name: partnerName || partner_name,
			start_ts: moment().subtract(1, 'days').utc().format('YYYYMMDDHHmmss'),
			end_ts: moment().utc().format('YYYYMMDDHHmmss'),
			alert_types: isInternalUser(user_role) ? 'hdderror,uncleanshutdown,videoloss' : 'uncleanshutdown,videoloss', // 'all',// FWSD-4420
			event_types: 'fatalgsensor,highspeed,inputsensor,%_clip,%_alert,%_event,forwardcollisionwarning,lanedeparture,pedestrianwarning,headwaywarning,followingdistancewarning,drowsiness,distraction,phoneuse,yawning,harshbraking,harshaccel,seatbelt,smoking', // 'all',
			device_ids: webfleetDeviceId ? webfleetDeviceId : 'all',
		};

		toggleLoaderModal(true);
		getEventsOfCompany(data)
			.then((res, err) => {
				console.log('!getRecent24Events res', res);

				const alertsList = [];
				res.data.response.map((device, i) => {
					device.alarms.map((alarm, j) => {
						alertsList.push({
							id: alarm.id,
							device_id: device.device_id,
							vehicle_name: device.vehicle_name,
							timestamp: alarm.timestamp,
							type: getAlarmTitle(constants.alertcheckboxes, alarm.type),
							event_id: alarm.event_id,
							event_type: alarm.type,
							event_display_type: '',
						})
					})
					device.events.map((event, k) => {
						alertsList.push({
							id: event.id,
							device_id:
								device.device_id,
							vehicle_name: device.vehicle_name,
							timestamp: event.timestamp,
							type: getAlarmTitle(constants.eventcheckboxestitle, event.event_type),
							event_id: event.id,
							event_type: event.event_type,
							event_display_type: event.event_display_type,
						})
					})
				})

				// setState({ ...state, alerts: alertsList, initialData: alertsList });
				setState((prevState) => { return { ...prevState, alerts: alertsList, initialData: alertsList } })

				toggleLoaderModal(false);

				if (res.data.response.length > 0) {
					let flag = false;
					res.data.response.map((device, i) => {
						if (device.alarms.length > 0 || device.events.length) {
							flag = true;
						}
					});

					if (!flag) {
						toastr.info('', 'The search returned no results');
					}
				} else {
					toastr.info('', 'The search returned no results');
				}
			})
			.catch((error) => {
				console.log('!getRecent24Events error:', error.response, error);
				// setState({ ...state, alerts: [], initialData: [] });
				setState((prevState) => { return { ...prevState, alerts: [], initialData: [] } })
				toggleLoaderModal(false);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const handleTabSelect = (key, id) => {
		// setState({ ...state, tabKey: +key, alerts: [] });
		setState((prevState) => { return { ...prevState, tabKey: +key, alerts: [] } })

		if (+key === 4) {
			getRecent24Events();
		}

		let label = ''
		switch (+key) {
			case 1:
				label = 'Vehicle Search'
				break;
			case 2:
				label = 'Fleet Search'
				break;
			case 3:
				label = 'Fleet Alerts & Events'
				break;
			case 4:
				label = 'Recent Alerts & Events'
				break
			case 5:
				label = 'Driver Search'
				break
			default:
				break;
		}
		sendGA({ category: user_role, action: 'Change Event Search Tab', label })
	}

	const onDateChanged1 = (current) => {
		// setState({ ...state, start_date: current });
		setState((prevState) => { return { ...prevState, start_date: current } })
	};

	const onDateChanged2 = (current) => {
		// setState({ ...state, end_date: current });
		setState((prevState) => { return { ...prevState, end_date: current } })
	};

	const searchAlertsEvents = (partnerName = null, companyName = null) => {
		// console.log('searchAlertsEvents1', state.selectedAlertType);
		// console.log('searchAlertsEvents2', state.selectedEventType);
		const {
			tabKey,
			start_date,
			end_date,
			selectedDeviceID,
			selectedAlertType,
			selectedEventType,
			selectedDevices,
			selectedAlertTypes,
			selectedEventTypes,
		} = state;

		if (!start_date
			|| !end_date
			|| (moment(end_date).diff(start_date) < 0)
		) {
			toastr.error('End date should be bigger than Start date');
		} else if (end_date.diff(start_date, 'days') > 31) {
			toastr.error('The maximum search period is 31 days');
		} else if (tabKey === 1
			&& !selectedDeviceID
		) {
			toastr.error('Please select a vehicle');
		} else if (tabKey === 1
			&& !selectedAlertTypes.length
			&& !selectedEventTypes.length
		) {
			toastr.error('Please select at least one alert or event definition');
		} else if (tabKey === 2
			&& !selectedDevices.length
		) {
			toastr.error('Please select at least one vehicle');
		} else if (tabKey === 2
			&& !selectedAlertType.length
			&& !selectedEventType.length
		) {
			toastr.error('Please select an alert or event type');
		} else if (tabKey === 3
			&& !selectedAlertTypes.length
			&& !selectedEventTypes.length
		) {
			toastr.error('Please select at least one alert or event definition');
		} else if (tabKey === 5
			&& !selectedDeviceID
		) {
			toastr.error('Please select a driver');
		} else if (tabKey === 5
			&& !selectedAlertTypes.length
			&& !selectedEventTypes.length
		) {
			toastr.error('Please select at least one alert or event definition');
		} else if (tabKey === 1) {
			const data = {
				start_ts: moment(start_date).utc().format('YYYYMMDDHHmmss'),
				end_ts: moment(end_date).utc().format('YYYYMMDDHHmmss'),
				alert_types: selectedAlertTypes,
				event_types: selectedEventTypes,
				device_id: selectedDeviceID,
			};

			toggleLoaderModal(true);
			getAlertsOfDevice(data)
				.then((res, err) => {
					console.log('!getAlertsOfDevice res', res);

					const vehicle = devices.filter(device => device.device_id === data.device_id);

					if ((res.data.response.alarms && res.data.response.alarms.length)
						|| (res.data.response.events && res.data.response.events.length)
					) {
						const alertsList = [];
						res.data.response.alarms.forEach((alarm) => {
							alertsList.push({
								id: alarm.id,
								device_id: data.device_id,
								vehicle_name: vehicle[0].vehicle_name,
								timestamp: alarm.timestamp,
								type: getAlarmTitle(constants.alertcheckboxes, alarm.type),
								event_id: alarm.event_id,
								event_type: alarm.type,
								event_display_type: '',
							})
						})
						res.data.response.events.forEach((event) => {
							alertsList.push({
								id: event.id,
								device_id: data.device_id,
								vehicle_name: vehicle[0].vehicle_name,
								timestamp: event.timestamp,
								type: getAlarmTitle(constants.eventcheckboxestitle, event.event_type),
								event_id: event.id,
								event_type: event.event_type,
								event_display_type: event.event_display_type,
							})
						})

						// setState({ ...state, alerts: alertsList, initialData: alertsList });
						setState((prevState) => { return { ...prevState, alerts: alertsList, initialData: alertsList } })
						toggleLoaderModal(false);
					} else {
						// setState({ ...state, alerts: [] });
						setState((prevState) => { return { ...prevState, alerts: [] } })
						toastr.info('', 'The search returned no results');
					}

					toggleLoaderModal(false);
				})
				.catch((error) => {
					console.log('!getAlertsOfDevice error:', error.response, error);

					// setState({ ...state, alerts: [] });
					setState((prevState) => { return { ...prevState, alerts: [] } })
					toggleLoaderModal(false);

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				});
		} else if (tabKey === 5) {
			const data = {
				start_ts: moment(start_date).utc().format('YYYYMMDDHHmmss'),
				end_ts: moment(end_date).utc().format('YYYYMMDDHHmmss'),
				alert_types: selectedAlertTypes,
				event_types: selectedEventTypes,
				driver_id: selectedDeviceID,
			}
			// FWSD-5343, if absent the alert_types param in the /alerts request then do not return data from the device_metadata_tb table
			if (!data.alert_types.length) {
				delete data.alert_types
			}

			toggleLoaderModal(true);
			apiCallGet('/driver/{driver_id}/alerts', data)
				.then((res, err) => {
					console.log('!getAlertsOfDevice res:', res, err)

					const { response } = res.data;
					// const response = [
					// 	{
					// 		"alarms": [
					// 			{
					// 				"event_id": null,
					// 				"id": 8176542,
					// 				"timestamp": "2020-09-04 11:08:40",
					// 				"type": "HighSpeed"
					// 			}
					// 		],
					// 		"events": [
					// 			{
					// 				"event_type": "HighSpeed",
					// 				"id": 1868957,
					// 				"timestamp": "2020-09-04 11:08:40"
					// 			}
					// 		],
					// 		"vehicle_name": "Martin",
					// 		"device_id": 86787754,
					// 		"driver_name": 'test Driver',
					// 		"driver_id": 123,
					// 	},
					// 	{
					// 		"alarms": [
					// 			{
					// 				"event_id": null,
					// 				"id": 8176542,
					// 				"timestamp": "2020-09-04 11:08:40",
					// 				"type": "HighSpeed"
					// 			}
					// 		],
					// 		"events": [
					// 			{
					// 				"event_type": "HighSpeed",
					// 				"id": 1868957,
					// 				"timestamp": "2020-09-04 11:08:40"
					// 			}
					// 		],
					// 		"vehicle_name": "VN",
					// 		"device_id": 123456789,
					// 		"driver_name": 'test Driver',
					// 		"driver_id": 123
					// 	}
					// ]


					if (response.length) {
						const alertsList = [];

						response.forEach((item) => {
							const { driver_id, driver_name, device_id, vehicle_name, alarms, events } = item;

							if ((alarms && alarms.length)
								|| (events && events.length)
							) {
								alarms.forEach((alarm) => {
									alertsList.push({
										id: alarm.id,
										device_id,
										vehicle_name,
										driver_id,
										driver_name,
										timestamp: alarm.timestamp,
										type: getAlarmTitle(constants.alertcheckboxes, alarm.type),
										event_id: alarm.event_id,
										event_type: alarm.type,
										event_display_type: '',
									})
								})
								events.forEach((event) => {
									alertsList.push({
										id: event.id,
										device_id,
										vehicle_name,
										driver_id,
										driver_name,
										timestamp: event.timestamp,
										type: getAlarmTitle(constants.eventcheckboxestitle, event.event_type),
										event_id: event.id,
										event_type: event.event_type,
										event_display_type: event.event_display_type,
									})
								})
							}
						})

						// setState({ ...state, alerts: alertsList, initialData: alertsList, loaderModal: false });
						setState((prevState) => { return { ...prevState, alerts: alertsList, initialData: alertsList, loaderModal: false } })
					} else {
						// setState({ ...state, alerts: [] });
						setState((prevState) => { return { ...prevState, alerts: [] } })
						toastr.info('', 'The search returned no results');
					}

					toggleLoaderModal(false);
				})
				.catch((error) => {
					console.log('!getAlertsOfDevice error:', error.response, error)
					// setState({ ...state, alerts: [] });
					setState((prevState) => { return { ...prevState, alerts: [] } })
					toggleLoaderModal(false);

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		} else {
			let data = null;

			if (tabKey === 2) {
				data = {
					user_role,
					company_name: companyName || company_name,
					partner_name: partnerName || partner_name,
					start_ts: moment(start_date).utc().format('YYYYMMDDHHmmss'),
					end_ts: moment(end_date).utc().format('YYYYMMDDHHmmss'),
					alert_types: _.map(selectedAlertType, 'value'),
					event_types: _.map(selectedEventType, 'value'),
					device_ids: (selectedDevices.length > LIMIT_IDS) ? (webfleetDeviceId ? webfleetDeviceId : 'all') : selectedDevices.join(','),
				};
			} else if (tabKey === 3) {
				data = {
					user_role,
					company_name: companyName || company_name,
					partner_name: partnerName || partner_name,
					start_ts: moment(start_date).utc().format('YYYYMMDDHHmmss'),
					end_ts: moment(end_date).utc().format('YYYYMMDDHHmmss'),
					alert_types: selectedAlertTypes.join(','),
					event_types: selectedEventTypes.join(','),
					device_ids: webfleetDeviceId ? webfleetDeviceId : 'all',
				};
			}

			toggleLoaderModal(true);
			getEventsOfCompany(data)
				.then((res) => {
					console.log('!getEventsOfCompany res:', res);

					let { response } = res.data;
					//* if user selected more then 250 devices we request the 'all' data and than filter them
					if (tabKey === 2 && selectedDevices.length > LIMIT_IDS) {
						response = response.filter(item => (item.device_id && selectedDevices.indexOf(item.device_id.toString()) > -1))
					}

					const alertsList = [];
					response.forEach((device) => {
						device.alarms.forEach((alarm) => {
							alertsList.push({
								id: alarm.id,
								device_id: device.device_id,
								vehicle_name: device.vehicle_name,
								timestamp: alarm.timestamp,
								type: getAlarmTitle(constants.alertcheckboxes, alarm.type),
								event_id: alarm.event_id,
								event_type: alarm.type,
								event_display_type: '',
							})
						})
						device.events.forEach((event) => {
							alertsList.push({
								id: event.id,
								device_id: device.device_id,
								vehicle_name: device.vehicle_name,
								timestamp: event.timestamp,
								type: getAlarmTitle(constants.eventcheckboxestitle, event.event_type),
								event_id: event.id,
								event_type: event.event_type,
								event_display_type: event.event_display_type,
							})
						})
					})

					// setState({ ...state, alerts: alertsList, initialData: alertsList });
					setState((prevState) => { return { ...prevState, alerts: alertsList, initialData: alertsList } })
					toggleLoaderModal(false);

					if (res.data.response.length > 0) {
						let flag = false;
						res.data.response.forEach((device) => {
							if (device.alarms.length > 0 || device.events.length) {
								flag = true;
							}
						});

						if (!flag) {
							toastr.info('', 'The search returned no results');
						}
					} else {
						toastr.info('', 'The search returned no results');
					}
				})
				.catch((error) => {
					console.log('!getEventsOfCompany error:', error.response, error);
					// setState({ ...state, alerts: [] });
					setState((prevState) => { return { ...prevState, alerts: [] } })
					toggleLoaderModal(false);

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				});
		}
	}

	const onResize = (event, { element, size }) => {
		// setState({ ...state, windowWidth: size.width, windowHeight: size.height });
		setState((prevState) => { return { ...prevState, windowWidth: size.width, windowHeight: size.height } })
	}

	const createCustomAlertVideo = (e, row, isADASEvent = null) => {
		e.preventDefault()
		console.log('createCustomAlertVideo request:', row);
		toggleLoaderModal(true);

		let defaultFunction = requestCustomAlertVideo
		if (isADASEvent) {
			defaultFunction = requestCustomAlertSnapshot
		}
		const data = {
			device_id: row.device_id,
			timestamp: row.timestamp,
			alert_type: row.event_type,
		}
		defaultFunction(data)
			.then((res, err) => {
				console.log('requestCustomAlertVideo res:', res);

				if (res.data.response.request_id) {
					const { alerts } = state;
					alerts.map((item, i) => {
						if (item.id === row.id && item.device_id === row.device_id && item.event_type === row.event_type) {
							item.event_id = res.data.response.request_id;
						}
						return item;
					});

					// setState({ ...state, alerts });
					setState((prevState) => { return { ...prevState, alerts } })
				}

				toggleLoaderModal(false);

				toastr.success('', 'Custom Video Requested');
			})
			.catch((error) => {
				console.log('requestCustomAlertVideo error:', error.response, error);

				toggleLoaderModal(false);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const handlePageChange = (page, sizePerPage) => {
		// setState({
		// 	...state,
		// 	page,
		// 	sizePerPage,
		// });
		setState((prevState) => { return { ...prevState, page, sizePerPage } })
	}

	const handleSortChange = (sortName, sortOrder) => {
		// setState({ ...state, sortName, sortOrder });
		setState((prevState) => { return { ...prevState, sortName, sortOrder } })
	}

	const handleSizePerPageChange = (sizePerPage) => {
		// setState({ ...state, sizePerPage });
		setState((prevState) => { return { ...prevState, sizePerPage } })
	}

	const eventtypeFormatter = (cell, row) => {
		// return eventTypeDetection({ type: row.event_type, event_display_type: row.event_display_type })

		// FWSD-4541
		if (row.event_display_type) {
			return row.event_display_type
		}

		return cell.replace(/Fatal/, 'Critical');
	}

	const timestampFormatter = (cell, row) => momenttz(`${cell}+00`).format('hh:mm:ss A MM/DD/YYYY');

	const viewEventClip = (e, row) => {
		console.log("viewing event")
		e.preventDefault();
		//* When sent to storage page from Event & Alert page, there should be a return tab: https://roscolive.atlassian.net/browse/FWSD-326
		// let states = null;
		// if (!props.donotuseredux) {
		//  states = {...state};
		// }
		//* Delete states.alerts;
		updateAlertsearch({ ...state });

		// setEventsRedirect({
		// 	event_device_id: row.device_id,
		// 	event_id: row.event_id,
		// 	event_ts: row.timestamp,
		// 	type: (type === 'storage_link') ? 'storage_local' : type,
		// })

		const params = {
			user_role: user_role,
			company_name: company_name,
			partner_name: partner_name,
			event_id: row.event_id,
		}

		getEventById(params)
			.then((resEvent, errEvent) => {
				const event = resEvent.data.response.event;
				if (event.is_flood) props.handleSelect(3, event);
				console.log("Event is in quarantine. Let's select it.")
				console.log('handleAlertClick getEventById resEvent, errEvent: ', resEvent, errEvent);
			})
			.catch((errorEvent) => {
				console.log('handleAlertClick getEventById errorEvent: ', errorEvent);
			});

		browserHistory.push({
			pathname: '/event',
			state: {
				event_device_id: row.device_id,
				event_id: row.event_id,
				event_ts: row.timestamp,
				type: (type === 'storage_link') ? 'storage_local' : type,
			},
		});

		// browserHistory.push({pathname: '/event', state: undefined})
		// browserHistory.replace({pathname: '/event', state: undefined})
		// browserHistory.push({pathname: '/event'})
	}

	const eventClipFormatter = (cell, row) => {
		if (row.event_id) {
			if (['custom_user', 'custom_partner'].includes(user_role)
				&& !_.find(user_permissions, { perm_category_id: 2, a_view: true })
			) {
				return <span />
			}
			return <a href="/" onClick={e => viewEventClip(e, row)}>{`View Event (id=${row.event_id})`}</a>
		}
		if (['custom_user', 'custom_partner'].includes(user_role)
			&& !_.find(user_permissions, { perm_category_id: 2, a_add: true })
		) {
			return <span />
		}
		if (user_role === 'sales_demo') {
			return <a href="javascript:void(0)" className="disabled">Request Video</a>
		}

		// https://roscolive.atlassian.net/browse/FWSD-3135
		// For the ADAS events only, the server should automatically create a custom request as if the user had clicked on it, except the custom request should download the snapshot only.
		let isADASEvent = false
		if (['PedestrianWarning', 'LaneDeparture', 'ForwardCollisionWarning', 'HeadwayWarning'].includes(row.type)) {
			isADASEvent = true
		}

		if (['SD Error', 'Improper shutdown'].indexOf(row.type) === -1) {
			return <a href="/" onClick={e => createCustomAlertVideo(e, row, isADASEvent)}>{`Request ${isADASEvent ? 'Snapshot' : 'Video'}`}</a>
		}
		else {
			return 'N/A'
		}
	};

	const formatCSVFilename = () => {
		let timeframe = moment(state.start_date).format('MM_DD_YYYY') + '__' + moment(state.end_date).format('MM_DD_YYYY')
		let filename = '';
		let alertList = '';
		if (state.alerts.length) {
			switch (state.tabKey) {
				case 1:
					filename = 'vehicle_search_' + (devices.find(el => el.device_id === state.selectedDeviceID).vehicle_name.replaceAll(' ', '_') || state.selectedDeviceID) + '_' + timeframe + '.csv'
					break;
				case 2:
					state.selectedAlertType.forEach(el => {
						alertList += el.label.toLowerCase().replaceAll(' ', '_') + '_';
					})
					state.selectedEventType.forEach(el => {
						alertList += el.label.toLowerCase().replaceAll(' ', '_') + '_';
					})
					filename = 'fleet_search_' + timeframe + '.csv'
					break;
				case 3:
					filename = 'fleet_alerts_and_events_' + alertList + timeframe + '.csv'
					break;
				case 4:
					filename = 'recent_events_and_alerts_' + moment().format('MM_DD_YYYY') + '.csv'
					break;
				case 5:
					let selectedDriver = state.drivers.find(el => el.id === state.selectedDeviceID)
					let driver_name = (selectedDriver.first_name.trim() + '_' + selectedDriver.last_name.trim())
					filename = 'driver_search_' + driver_name + '_' + timeframe + '.csv'
					break;
			}
		}
		else {
			filename = 'event_alert_search.csv'
		}
		return filename
	}

	const options = {
		...paginationExportOptions,
		onPageChange: handlePageChange,
		onSizePerPageList: handleSizePerPageChange,
		sortName: state.sortName,
		sortOrder: state.sortOrder,
		onSortChange: handleSortChange,
		page: state.page,
		sizePerPage: state.sizePerPage,
	}

	const { start_date, tabKey, searchText, showSearchBlock, initialData, alerts, windowHeight, windowWidth, drivers } = state;

	// console.log('PROPS TrackTraceAlertSearch: ', props)
	// console.log('STATE TrackTraceAlertSearch: ', state)

	return (
		<Draggable
			handle=".modal-header"
			bounds="body"
		>
			<div className="alert-search-container track-trace-alert" style={{ width: `${windowWidth}px` }}>
				<div className="modal-lg1">
					<Resizable
						height={windowHeight}
						width={windowWidth}
						onResize={onResize}
						axis="both"
						minConstraints={[100, 100]}
					>
						<div className="modal-content" role="document">
							<div className="modal-header" style={{ cursor: 'move' }}>
								<h4 id="contained-modal-title-lg" className="modal-title">
									Event Search
								</h4>
								<CustomButton size="icon" prefix="close" onClick={hideWindow}>
									<span aria-hidden="true">×</span>
									<span className="sr-only">Close</span>
								</CustomButton>
							</div>
							<div className="modal-body" style={{ height: `${windowHeight}px`, maxHeight: 'calc(100vh - 100px)' }}>
								<div className="search-body">
									<div className="header">
										<div onClick={() => setState({ ...state, showSearchBlock: !showSearchBlock })}>
											<span>
												{showSearchBlock ?
													<svg width="21" height="21" fill="currentColor" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" /></svg>
													: <svg width="21" height="21" fill="currentColor" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" /></svg>
												}
											</span>
											<span>{showSearchBlock ? 'Collapse' : 'Expand'}</span>
										</div>
									</div>

									<div className={classnames('search-content', { hide: !showSearchBlock })}>
										<Tabs activeKey={tabKey} onSelect={handleTabSelect} id="controlled-tab-example" className='rosco-tabs'>
											<Tab eventKey={4} title="Recent Alerts & Events">
												<Recent24
													alertsearch={alertsearch.alertsearch}
													term={searchText}
													alerts={initialData}
													update={updateAlertsList}
													type={type}
												/>
											</Tab>

											<Tab eventKey={1} title="Vehicle Search">
												<EventsAlertsTab
													name="vehicle"
													title="Select a vehicle and alerts to search"
													items={devices}
													getSelectedData={getSelectedData}
													alertsearch={alertsearch.alertsearch}
													type={type}
													withSearch
												/>
											</Tab>
											<Tab eventKey={2} title="Fleet Search">
												<FleetSearch
													devices={devices}
													getSelectedDevices={getSelectedDevices}
													alertsearch={alertsearch.alertsearch}
													type={type}
												/>
											</Tab>

											{/*<Tab eventKey={3} title="Fleet Alerts & Events">*/}
											{/*	<FleetAlertsEvents*/}
											{/*		getSelectedAlerts={getSelectedAlerts}*/}
											{/*		getSelectedEvents={getSelectedEvents}*/}
											{/*		alertsearch={alertsearch.alertsearch}*/}
											{/*		type={type}*/}
											{/*	/>*/}
											{/*</Tab>*/}
											<Tab eventKey={3} title="Fleet Alerts & Events">
												<EventsAlertsTab
													name="alertsEvents"
													title="Select vehicles and an alert type"
													getSelectedAlerts={getSelectedAlerts}
													getSelectedEvents={getSelectedEvents}
													alertsearch={alertsearch.alertsearch}
													type={type}
												/>
											</Tab>

											{/*<Tab eventKey={5} title="Driver Search">*/}
											{/*	<DriverSearch*/}
											{/*		drivers={drivers}*/}
											{/*		getSelectedData={getSelectedData}*/}
											{/*		alertsearch={alertsearch.alertsearch}*/}
											{/*		type={type}*/}
											{/*	/>*/}
											{/*</Tab>*/}
											<Tab eventKey={5} title="Driver Search">
												<EventsAlertsTab
													name="driver"
													title="Select a driver and alerts to search"
													items={drivers}
													getSelectedData={getSelectedData}
													alertsearch={alertsearch.alertsearch}
													type={type}
													withSearch
												/>
											</Tab>
										</Tabs>

										{tabKey !== 4
											&& (
												<div className="row search-control date-container-alert-search">
													<div className="col-xs-12 col-sm-4 col-md-4 date-field-item">
														<span className="label-text">Start:</span>
														<div className="date-field">
															<DateTime
																defaultValue={moment(start_date).format('MM/DD/YYYY hh:mm A')}
																value={moment(start_date).format('MM/DD/YYYY hh:mm A')}
																timeFormat="hh:mm A"
																inputProps={{ readOnly: true }}
																// closeOnSelect={true}
																onChange={onDateChanged1}
															/>
														</div>
													</div>
													<div className="col-xs-12 col-sm-4 col-md-4 date-field-item">
														<span className="label-text">End:</span>
														<div className="date-field">
															<DateTime
																defaultValue={moment(state.end_date).format('MM/DD/YYYY hh:mm A')}
																value={moment(state.end_date).format('MM/DD/YYYY hh:mm A')}
																timeFormat="hh:mm A"
																inputProps={{ readOnly: true }}
																// closeOnSelect={true}
																onChange={onDateChanged2}
															/>
														</div>
													</div>
													<div className="col-xs-12 col-sm-4 col-md-4 text-right">
														<CustomButton variant="primary" onClick={() => searchAlertsEvents()}>Search</CustomButton>
													</div>
												</div>
											)}
									</div>
								</div>

								<div className={classnames('col-md-121 search-result ', { 'full-table': !showSearchBlock, 'fleet-search': (tabKey === 2), recent24: (tabKey === 4) })}>
									<div className="event-alert-search-table">
										<BootstrapTable
											ref={tableEventSearch}
											data={alerts}
											{...tableExportOptions}
											options={options}
											csvFileName={() => formatCSVFilename()}
										>
											<TableHeaderColumn
												hidden={tabKey !== 5}
												dataField="driver_name"
												width="18%"
												dataSort
												csvHeader="Driver Name"
											>
												Driver
											</TableHeaderColumn>
											<TableHeaderColumn
												dataField="vehicle_name"
												width="18%"
												dataSort
												csvHeader="Vehicle Name"
											>
												Vehicle
											</TableHeaderColumn>
											<TableHeaderColumn
												dataField="type"
												width="30%"
												dataSort
												dataFormat={eventtypeFormatter}
												csvHeader="Event/Alert Type"
											>
												Alert / Event
											</TableHeaderColumn>
											<TableHeaderColumn
												isKey
												dataField="timestamp"
												width="26%"
												dataSort
												dataFormat={timestampFormatter}
												csvHeader="Date/Time"
											>
												Alert Time
											</TableHeaderColumn>
											<TableHeaderColumn
												hidden={!!webfleetDeviceId}
												width="26%"
												dataSort={false}
												dataFormat={eventClipFormatter}
												export={false}
											>
												Video
											</TableHeaderColumn>
										</BootstrapTable>
									</div>
								</div>
							</div>
							<div className="modal-footer" />

							{loaderModal}
						</div>
					</Resizable>
				</div>
			</div>
		</Draggable>
	)
}

export default connect(
	state => ({
		alertsearch: state.alertsearch,
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		updateAlertsearch: (alertsearch) => {
			dispatch({ type: 'GET_ALERTSEARCH_SUCCESS', payload: alertsearch });
		},
		// setEventsRedirect: (state) => {
		// 	dispatch({ type: 'SET_EVENTS_REDIRECT', payload: state });
		// },
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(TrackTraceAlertSearch);
