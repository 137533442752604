import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Button, Modal } from 'react-bootstrap'

import SearchBar from '../../../SearchBar'
import VehicleSelectBox from '../../../dump/menuComponents/VehicleSelectBox'
import { vehicleReportFields } from '../ReportsHelpers'
import CategoryItem from '../../../dump/menuComponents/CategoryItem'
import CustomButton from '../../../CustomButton'
import { isInternalUser } from '../../../helpers/constants'

import { getUsers } from '../../../../action/ClientActions'
import { getDevicesList } from '../../../../action/DeviceActions'
import { constants } from '../../../helpers/constants'
import { sendGA } from '../../../helpers/helperUsers'
import { getCustomReports, addCustomReport } from '../../../../action/ReportsActions'

const NewReportModal = (props) => {
    const { user, company, userReports, onHide, loaderModal, toggleLoaderModal } = props;
    const userRole = user.roles[0];

    const [step, setStep] = useState(2);
    const [type, setType] = useState('vehicle');
    const [fields, setFields] = useState(['vehicle_name']);
    const [name, setName] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [totalVehicles, setTotalVehicles] = useState([]);
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [errors, setErrors] = useState([]);
    const [customUserReports, setCustomUserReports] = useState([]);

    useEffect(() => {
        getReportOptions();
        if (!userReports.length) {
            getAllCustomReports()
        }
        else {
            setCustomUserReports(userReports)
        }
    }, [])

    const getReportOptions = () => {
        let reqArray = [];
        reqArray.push(getDevicesList(company.company_id));
        if (['system_admin', 'customer_service', 'partner', 'fleet_manager', 'custom_user', 'group_manager', 'sales_demo', 'custom_partner'].indexOf(userRole) > -1) {
            reqArray.push(getUsers(userRole, company))
        }
        toggleLoaderModal(true);
        Promise.all(reqArray)
            .then(res => {
                let devices = res[0].data.response.devices;
                let users = [];
                if (res.length > 1) {
                    users = res[1].data.response.users.filter(user => user.email !== props.user.email);
                }
                setTotalVehicles(devices)
                setTotalUsers(users)
                setVehicles(devices.map(vehicle => vehicle.device_id))
                toggleLoaderModal(false);
            })
            .catch(err => {
                toggleLoaderModal(false);
                toastr.error('An unknown error has occured. Please try again later.');
            });
    }

    // FWSD-5293
    const getAllCustomReports = () => {
        const { id, email } = user
        toggleLoaderModal(true)
        getCustomReports(email, company.company_id)
            .then(res => {
                const customReports = res.data.response.filter(({ owner_id }) => owner_id === id)
                setCustomUserReports(customReports.map(({ name }) => name))
                toggleLoaderModal(false)
            })
            .catch(err => {
                toggleLoaderModal(false)
                toastr.error('There was an error getting your custom reports. Please try again later.')
            })
    }

    const selectType = (type) => {
        const newFields = [...fields];
        if (type === 'vehicle') {
            newFields.push('vehicle_name');
        }
        else {
            newFields.push('driver_name');
        }
        setStep(2)
        setType(type)
        setFields(newFields)
    }

    const setField = (field) => {
        const newFields = [...fields]
        if (newFields.includes(field)) {
            newFields.splice(newFields.indexOf(field), 1)
        }
        else {
            newFields.push(field);
            sendGA({
                category: userRole,
                action: 'Add Field',
                label: field
            })
        }

        setFields(newFields)
        setErrors([])
    }

    const setSelectedVehicles = (vehicles) => {
        setVehicles(vehicles)
        setErrors([])
    }

    const setUser = (user) => {
        const newUsers = [...users]
        if (newUsers.includes(user)) {
            newUsers.splice(newUsers.indexOf(user), 1)
        }
        else {
            newUsers.push(user);
        }

        setUsers(newUsers)
        setErrors([])
    }

    const selectAllUsers = (e) => {
        let users = []
        if (e.target.checked) {
            users = totalUsers.map(user => {
                return user.email
            })
        }
        else {
            users = []
        }
        setUsers(users)
        setErrors([])
    }

    const nextStep = () => {
        if (!name) {
            setErrors({ ...errors, name: 'Please enter a name for this report' })
        }
        else if (!name.trim() || !/^[a-zA-Z0-9 ]+$/.test(name)) {
            setErrors({ ...errors, name: 'Enter a valid name for this report' })
        }
        else if (customUserReports.length && customUserReports.includes(name)) {
            setErrors({ ...errors, name: 'Entered report name already exists' })
        }
        else if (fields.length <= 1) {
            setErrors({ ...errors, fields: 'Please select more than one field' })
        }
        else if (!totalVehicles.length) {
            setErrors({ ...errors, fields: 'Please add at least one company device' })
        }
        else {
            setStep(3)
        }
    }

    const goToShareOptions = () => {
        if (!vehicles.length) {
            setErrors({ ...errors, assets: 'Please select at least one vehicle' })
        }
        else {
            setStep(4)
        }
    }

    const addReport = () => {
        if (vehicles.length === 0) {
            setErrors({ ...errors, assets: 'Please select at least one vehicle' })
        }
        else {
            const data = {
                name: name,
                type: type,
                fields: fields,
            }
            if (vehicles.length !== totalVehicles.length) {
                data.vehicles = vehicles
            }
            if (users.length) {
                data.users = users
            }
            toggleLoaderModal(true);
            addCustomReport(data)
                .then(res => {
                    toggleLoaderModal(false);
                    props.goToCustomReports();
                    toastr.success('Successfully created new report')
                    sendGA({
                        category: userRole,
                        action: 'Add Report',
                        label: fields.join()
                    })
                })
                .catch(err => {
                    toggleLoaderModal(false);
                    toastr.error('There was an error creating a custom report. Please try again later.')
                });
        }
    }

    const allUsersChecked = users.length === (totalUsers && totalUsers.length);

    return (
        <Modal
            size='lg'
            show
            onHide={onHide}
            className="reports-modal modal-lg-size"
        >
            <Modal.Header closeButton>
                <Modal.Title>{'New Report' + (step > 2 ? (' - ' + (type.charAt(0).toUpperCase() + type.substring(1))) : '')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="new-report-body">
                    {step === 1 &&
                        <div className='type-select'>
                            <CategoryItem
                                text={'Vehicle / Fleet'}
                                onClick={() => selectType('vehicle')}
                                withArrow
                            />
                            <CategoryItem
                                text={'Driver'}
                                onClick={() => selectType('driver')}
                                withArrow
                            />
                        </div>
                    }
                    {step === 2 &&
                        <div className='fields-select'>
                            <div className='report-info'>
                                <h4 className='category-title'>
                                    Report Name
                                </h4>
                                <input value={name} type='text' placeholder='Report Name' className={'vehicle-search report-input' + (errors['name'] ? ' error' : '')}
                                    onChange={(e) => setName(e.target.value)}
                                    onFocus={() => setErrors([])}
                                />
                                <div className={'error-msg'}>{errors['name']}</div>
                            </div>
                            <div className='error-msg text-center'>{errors['fields']}</div>
                            {vehicleReportFields.map(category =>
                                <div className='fields-category' key={category.name}>
                                    <h4 className='category-title'>{category.name}</h4>
                                    <div className='category-fields'>
                                        {category.fields.map(field => {
                                            // Only for Tenna and internal users (FWSD-7355)
                                            if (!(user.company_id === 2171 || isInternalUser(userRole)) && field.fieldName === 'camera_block') {
                                                return;
                                            }
                                            return (<div className='field-container' key={field.name}>
                                                <input type='checkbox'
                                                    readOnly={field.defaultSelected}
                                                    onChange={() => !field.defaultSelected && setField(field.fieldName)}
                                                    checked={fields.includes(field.fieldName)}
                                                />
                                                <span className='field'>{field.name}</span>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    {step === 3 &&
                        <div className='details-select'>
                            <h4 className='category-title'>
                                Report Options
                            </h4>
                            <div className='modal-column'>
                                {totalVehicles.length > 0 &&
                                    <VehicleSelectBox
                                        vehicles={totalVehicles}
                                        selectedVehicleIds={vehicles}
                                        error={errors['assets']}
                                        onToggle={setSelectedVehicles}
                                    />
                                }
                            </div>
                        </div>
                    }
                    {step === 4 &&
                        <div className='report-category details-select'>
                            <h4 className='category-title'>
                                Share Options (Optional)
                            </h4>
                            <div className='modal-column select-box'>
                                <div className='select-box-header'>
                                    <div className='column-title'>Users</div>
                                    <input type='checkbox' checked={allUsersChecked} onChange={selectAllUsers} className='select-all-checkbox' />
                                    <span className='select-all-label'>{'Select All (' + users.length + ' selected)'}</span>
                                </div>
                                <SearchBar term={userSearch} onSearch={(e) => setUserSearch(e.target.value)} />
                                <div className='select-box-body'>
                                    <div className={'asset-select' + (allUsersChecked ? ' disabled' : '')}>
                                        {totalUsers && totalUsers.map((user) => {
                                            let name = user.first_name + ' ' + user.last_name;
                                            if (name && (!userSearch || name.toLowerCase().includes(userSearch.toLowerCase()))) {
                                                return (
                                                    <div className='asset-select-wrapper' key={user.id}>
                                                        <input type='checkbox' checked={users.includes(user.email)} onChange={() => setUser(user.email)} />
                                                        <span className='asset-name'>{name + ' - ' + constants.roleNames[user.roles[0]]}</span>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {step === 2 && <>
                    <CustomButton
                        variant='primary'
                        onClick={nextStep}
                    >
                        Next
                    </CustomButton>
                </>}
                {step === 3 && <>
                    <CustomButton
                        variant='primary-outline'
                        onClick={() => setStep(2)}
                    >
                        Back
                    </CustomButton>
                    {['user', 'partner_view'].indexOf(userRole) > -1 ?
                        <CustomButton
                            variant='primary'
                            onClick={() => addReport()}
                        >
                            Add
                        </CustomButton> :
                        <CustomButton
                            variant='primary'
                            onClick={goToShareOptions}
                        >
                            Next
                        </CustomButton>
                    }
                </>}
                {step === 4 && <>
                    <CustomButton
                        variant='primary-outline'
                        onClick={() => setStep(3)}
                    >
                        Back
                    </CustomButton>
                    <CustomButton
                        variant='primary'
                        onClick={() => addReport()}
                    >
                        Add
                    </CustomButton>
                </>}
            </Modal.Footer>
            {loaderModal}
        </Modal>
    )
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
        loaderModal: state.loader.loaderModal,
    }),
    dispatch => ({
        toggleLoaderModal: (show) => {
            dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
        }
    }),
)(NewReportModal);
