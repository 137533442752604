// If configuration setting value is enough for displaying then we set config node to "setting" object key.
// If value of "setting" object key is true/false then we convert it to ON/OFF. But if value 1/0 and we want display ON/OFF then we set the "type" object key = boolean.
// If "setting" key is empty then we use separate functions to display the value of each setting in the getSettingValue function
// last id = 112
export const profileSettings = {
	dv6: {
		general: [
			{id:1, name: 'ADAS Audio Alert', setting: 'System.Chimes.Adas', depreciated: true},
			{id:2, name: 'Camera Event Chime', setting: 'Event.BuzzerEnabled'},
			{id:3, name: 'DMS Alert Voice', setting: 'System.Chimes.Dms', depreciated: true},
			{id:4, name: 'Event Chime Volume', setting: 'System.Chimes.Volume'},
			{id:5, name: 'Overlay Speed on Video', setting: 'Overlay.ShowSpeed'},
			{id:6, name: 'Parking Duration', setting: 'General.IgnitionShutdownDelay'},
			{id:7, name: 'Parking Mode', setting: ''},
			{id:8, name: 'Potential Ch2 Obstruction Detection', setting: 'Event.Dms.CameraObstruction.Enabled'},
			{id:9, name: 'Potential Ch2 Obstruction Detection Voice Prompt', setting: ''},
			{id:10, name: 'Speed Unit', setting: ''},
			{id:11, name: 'Timezone', setting: 'General.TimeZone'},
		],
		video: [
			{id:12, name: 'Channel 1', setting: 'Channels.0.Enabled'},
			{id:13, name: 'Channel 1 Recording', setting: ''},
			{id:14, name: 'Channel 2', setting: 'Channels.1.Enabled'},
			{id:15, name: 'Channel 2 Audio', setting: 'Channels.1.Audio', type: 'boolean'},
			{id:16, name: 'Channel 2 Recording', setting: ''},
			{id:17, name: 'Channel 3', setting: 'Channels.2.Enabled'},
			{id:18, name: 'Channel 3 Audio', setting: 'Channels.2.Audio', type: 'boolean'},
			{id:19, name: 'Channel 3 Recording', setting: ''},
		],
		event: [
			{id:20, name: 'ADAS - Forward Collision', setting: 'Event.Adas.ForwardCollision.Enabled'},
			{id:21, name: 'ADAS - Forward Collision Upload Type', setting: ''},
			{id:110, name: 'ADAS - Forward Collision Event Voice Prompt', setting: 'System.Chimes.ForwardCollision'},
			{id:22, name: 'ADAS - Headway Monitoring', setting: 'Event.Adas.Headway.Enabled'},
			{id:23, name: 'ADAS - Headway Monitoring Upload Type', setting: ''},
			{id:111, name: 'ADAS - Headway Monitoring Event Voice Prompt', setting: 'System.Chimes.Headway'},
			{id:24, name: 'ADAS - Tailgating', setting: 'Event.Adas.FollowingDistance.Enabled'},
			{id:25, name: 'ADAS - Tailgating Upload Type', setting: ''},
			{id:112, name: 'ADAS - Tailgating Event Voice Prompt', setting: 'System.Chimes.FollowingDistance'},
			{id:26, name: 'ADAS - Minimum Speed', setting: ''},
			{id:27, name: 'ADAS - Following Distance Time', setting: 'Event.Adas.Headway.Ttc.TriggerThreshold'},
			{id:28, name: 'DMS - Distraction', setting: 'Event.Dms.Distraction.Enabled'},
			{id:29, name: 'DMS - Distraction Upload Type', setting: ''},
			{id:93, name: 'DMS - Distraction Sensitivity', setting: ''},
			{id:109, name: 'DMS - Distraction Event Voice Prompt', setting: 'System.Chimes.Distraction'},
			{id:30, name: 'DMS - Yawning', setting: 'Event.Dms.Yawning.Enabled'},
			{id:31, name: 'DMS - Yawning Upload Type', setting: ''},
			{id:32, name: 'DMS - Yawning Sensitivity', setting: ''},
			{id:108, name: 'DMS - Yawning Event Voice Prompt', setting: 'System.Chimes.Yawning'},
			{id:33, name: 'DMS - Dozing', setting: 'Event.Dms.Dozing.Enabled'},
			{id:34, name: 'DMS - Dozing Upload Type', setting: ''},
			{id:35, name: 'DMS - Dozing Sensitivity', setting: ''},
			{id:107, name: 'DMS - Dozing Event Voice Prompt', setting: 'System.Chimes.Drowsiness'},
			{id:36, name: 'DMS - Phone Use', setting: 'Event.Dms.PhoneUse.Enabled'},
			{id:37, name: 'DMS - Phone Use Upload Type', setting: ''},
			{id:38, name: 'DMS - Phone Use Sensitivity', setting: ''},
			{id:106, name: 'DMS - Phone Use Voice Prompt', setting: 'System.Chimes.PhoneUse'},
			{id:94, name: 'DMS - Smoking', setting: 'Event.Dms.Smoking.Enabled'},
			{id:95, name: 'DMS - Smoking Upload Type', setting: ''},
			{id:96, name: 'DMS - Smoking Sensitivity', setting: ''},
			{id:97, name: 'DMS - Smoking Event Voice Prompt', setting: 'System.Chimes.Smoking'},
			// {id:98, name: 'DMS - Seatbelt', setting: 'Event.Dms.Seatbelt.Enabled', isInternal: true},
			{id:98, name: 'DMS - Seatbelt', setting: 'Event.Dms.Seatbelt.Enabled'},
			{id:99, name: 'DMS - Seatbelt Upload Type', setting: ''},
			{id:100, name: 'DMS - Seatbelt Sensitivity', setting: ''},
			{id:101, name: 'DMS - Seatbelt Event Voice Prompt', setting: 'System.Chimes.Seatbelt'},
			{id:39, name: 'Driver Event', setting: 'Event.Sensor.4.Enabled'},
			{id:40, name: 'Driver Event Upload Type', setting: ''},
			{id:41, name: 'Harsh Acceleration', setting: 'Event.HarshAccel.Enabled'},
			{id:42, name: 'Harsh Acceleration Upload Type', setting: ''},
			// {id:43, name: 'Harsh Acceleration Sensitivity', setting: 'Event.HarshAccel.Threshold'},
			{id:43, name: 'Harsh Acceleration Sensitivity', setting: ''},
			{id:104, name: 'Harsh Acceleration Event Chime', setting: 'System.Chimes.HarshAccel'},
			{id:44, name: 'Harsh Braking', setting: 'Event.HarshBraking.Enabled'},
			{id:45, name: 'Harsh Braking Upload Type', setting: ''},
			// {id:46, name: 'Harsh Braking Sensitivity', setting: 'Event.HarshBraking.Threshold'},
			{id:46, name: 'Harsh Braking Sensitivity', setting: ''},
			{id:103, name: 'Harsh Braking Event Chime', setting: 'System.Chimes.HarshBraking'},
			{id:47, name: 'High G-Force', setting: 'Event.Accelerometer.Enabled'},
			{id:48, name: 'High G-Force Upload Type', setting: ''},
			{id:49, name: 'High G-Force Sensitivity', setting: 'Event.Accelerometer.FatalThreshold.X'},
			{id:102, name: 'High G-Force Event Chime', setting: 'System.Chimes.Accelerometer'},
			{id:50, name: 'Max Speed', setting: 'Event.HighSpeed.Enabled'},
			{id:51, name: 'Max Speed Upload Type', setting: ''},
			{id:52, name: 'Max Speed Threshold', setting: ''},
			{id:105, name: 'Max Speed Event Chime', setting: 'System.Chimes.HighSpeed'},
		],
	},
	dv4: {
		general: [
			{id:53, name: 'Camera Event Chime', setting: 'Event.BuzzerEnabled'},
			{id:54, name: 'Display Speed on LCD', setting: 'System.DisplaySpeed'},
			{id:55, name: 'LCD Backlight Day Mode', setting: 'System.BacklightHigh'},
			{id:56, name: 'LCD Backlight Night Mode', setting: 'System.BacklightLow'},
			{id:57, name: 'Overlay Speed on Video', setting: 'Overlay.ShowSpeed'},
			{id:58, name: 'Parking Duration', setting: 'General.IgnitionShutdownDelay'},
			{id:59, name: 'Parking Mode', setting: ''},
			{id:60, name: 'Speed Unit', setting: ''},
			{id:61, name: 'Timezone', setting: 'General.TimeZone'},
		],
		video: [
			{id:62, name: 'Channel 1', setting: 'Channels.0.Enabled'},
			{id:63, name: 'Channel 1 Recording', setting: ''},
			{id:64, name: 'Channel 2', setting: 'Channels.1.Enabled'},
			{id:65, name: 'Channel 2 Audio', setting: 'Channels.1.Audio', type: 'boolean'},
			{id:66, name: 'Channel 2 Recording', setting: ''},
			{id:67, name: 'Channel 3', setting: 'Channels.2.Enabled'},
			{id:68, name: 'Channel 3 Audio', setting: 'Channels.2.Audio', type: 'boolean'},
			{id:69, name: 'Channel 3 Recording', setting: ''},
			{id:70, name: 'Channel 4', setting: 'Channels.3.Enabled'},
			{id:71, name: 'Channel 4 Audio', setting: 'Channels.3.Audio', type: 'boolean'},
			{id:72, name: 'Channel 4 Recording', setting: ''},
		],
		event: [
			{id:73, name: 'ADAS - Lane Departure Warning', setting: 'Event.Adas.LaneDeparture.Enabled'},
			{id:74, name: 'ADAS- Forward Collision Warning', setting: 'Event.Adas.ForwardCollision.Enabled'},
			{id:75, name: 'ADAS - Pedestrian Collision Warning', setting: 'Event.Adas.Pedestrian.Enabled'},
			{id:76, name: 'ADAS - Speed Warning Threshold', setting: 'Event.Adas.SpeedThreshold'},
			{id:77, name: 'DMS - Distraction', setting: 'Event.Dms.Distraction.Enabled'},
			{id:78, name: 'DMS - Distraction Upload Type', setting: ''},
			{id:79, name: 'DMS - Drowsiness/Looking Down', setting: 'Event.Dms.Dozing.Enabled'},
			{id:80, name: 'DMS - Drowsiness/Looking Down Upload Type', setting: ''},
			{id:81, name: 'DMS - Phone Use/Hand to Head', setting: 'Event.Dms.PhoneUse.Enabled'},
			{id:82, name: 'DMS - Phone Use/Hand to Head Upload Type', setting: ''},
			{id:83, name: 'Driver Event', setting: 'Event.PanicButton.Enabled'},
			{id:84, name: 'Driver Event Upload Type', setting: ''},
			{id:85, name: 'External Driver Event', setting: 'Event.Sensor.4.Enabled'},
			{id:86, name: 'External Driver Event Upload Type', setting: ''},
			{id:87, name: 'High G-Force', setting: 'Event.Accelerometer.Enabled'},
			{id:88, name: 'High G-Force Upload Type', setting: ''},
			{id:89, name: 'High G-Force Sensitivity', setting: 'Event.Accelerometer.FatalThreshold.X'},
			{id:90, name: 'Max Speed', setting: 'Event.HighSpeed.Enabled'},
			{id:91, name: 'Max Speed Upload Type', setting: ''},
			{id:92, name: 'Max Speed Threshold', setting: ''},
		],
	}
}